<template>
    <el-dialog :visible.sync="visible" title="编辑库存量" width="450px">
        <el-form ref="amountForm" :rules="rules" :model="model" size="small" label-width="80px">
            <el-form-item label="库存量" prop="amount">
                <el-input v-model="model.amount"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input v-model="model.remark" type="textarea" :rows="2"></el-input>
            </el-form-item>
        </el-form>
        <div class="handle_form">
            <el-button size="small" type="primary" @click="makeSure">确定</el-button>
            <el-button size="small" @click="visible = false">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        methods: {
            makeSure() {
                this.$refs.amountForm.validate(valide => {
                    if (valide) {
                        this.$http.post("/stock/update", this.model).then(res => {
                            this.$message.success({
                                message: res.msg
                            });
                            this.visible = false;
                            this.$emit("update");
                        });
                    }
                });
            },
            init(stockId) {
                this.model.stockId = stockId;
                this.visible = true;
            }
        },
        data() {
            return {
                visible: false,

                model: {
                    amount: "",
                    remark: ""
                },
                rules: {
                    amount: [{ required: true, message: "库存量不为空", trigger: "blur" }]
                }
            };
        }
    };
</script>
<style scoped>
    .handle_form {
        text-align: center;
    }
</style>
