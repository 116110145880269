<template>
    <el-dialog :visible.sync="visible" title="操作记录">
        <jy-table :data="dataList">
            <jy-table-column type="index" label="序号"></jy-table-column>
            <jy-table-column label="操作时间" prop="createT"></jy-table-column>
            <jy-table-column label="原值" prop="bAmount"></jy-table-column>
            <jy-table-column label="新值" prop="aAmount"></jy-table-column>
            <jy-table-column label="备注" prop="remark"></jy-table-column>
            <jy-table-column label="操作人" prop="cUserNa"></jy-table-column>
        </jy-table>
    </el-dialog>
</template>

<script>
    export default {
        methods: {
            init(stockId) {
                this.$http.post("/stock/getOpList", stockId).then(res => {
                    this.dataList = res.detail;
                });
                this.visible = true;
            }
        },
        data() {
            return {
                dataList: [],

                visible: false
            };
        }
    };
</script>
<style></style>
